<p-dialog id="params-form" [(visible)]="visibility" [modal]="true" contentStyleClass="py-0"
    [style]="{'min-width': '70rem', 'max-width': '70rem', 'min-height': '45rem', 'max-height': '100vh'}"
    (keydown.enter)="onOkDialog()" (keydown.escape)="onCancelDialog()"
    (onShow)="onShowDialog()" (onClose)="onCloseDialog()" (onHide)="onHideDialog()">
    <ng-template pTemplate="header">
        <span class="p-dialog-title"><i class="pi pi-fw pi-table mr-2"></i> <span i18n>Parametry konkursu</span></span>
    </ng-template>

    <p-tabMenu #paramsTabMenu [model]="paramsTabMenuItems" [(activeItem)]="paramsActiveItem">
    </p-tabMenu>

    <form [formGroup]="view.cfg.form">
        <!-- Podstawowe -->
        <div *ngIf="paramsActiveItem === paramsTabMenuItems[0]" class="formgrid grid pt-3">
            <div class="field col-12">
                <div class="param-label" i18n>Wydarzenie:</div>
                <p-dropdown styleClass="w-full"
                    [options]="view.myEvents" optionLabel="eventName" optionValue="eventName" [editable]="true" [filter]="true"
                    placeholder="Wybierz wydarzenie z listy lub wpisz nowe" i18n-placeholder formControlName="eventName">
                    <ng-template let-event pTemplate="item">
                        <div class="flex justify-content-between">
                            <div>{{ event.eventName }}</div>
                            <div class="text-500">{{ event.eventDate }}</div>
                        </div>
                    </ng-template>
                </p-dropdown>
            </div>
            <div class="field col-12 md:col-9">
                <div class="param-label" i18n>Konkurs:</div>
                <input class="w-full" type="text" pInputText formControlName="title" />
            </div>
            <div class="field col-12 md:col-3">
                <div class="param-label" i18n>Data konkursu:</div>
                <p-calendar id="params-date" dateFormat="yy.mm.dd" styleClass="w-full" formControlName="eventDate" [showIcon]="true"
                            appendTo="body"></p-calendar>
            </div>
            <div class="field col-12">
                <div class="param-label" i18n>Krótki opis:</div>
                <input class="w-full" type="text" pInputText formControlName="shortDescr" />
            </div>
            <div class="field col-12 md:col-4">
                <div class="param-label" i18n>Klasa:</div>
                <p-multiSelect styleClass="w-full" (onChange)="initialize(true)"
                    [options]="classNoOptions" formControlName="classes" [showToggleAll]="false" [showHeader]="false" [showClear]="false"
                    [selectedItemsLabel]="selectedItemsLabel"
                    [filter]="false" optionLabel="name" optionValue="value" appendTo="body">
                </p-multiSelect>
            </div>
            <div #articleInParams class="field col-12 md:col-4" [pTooltip]="articleTooltip" tooltipPosition="top">
                <div class="param-label" i18n>Przepisy FEI:</div>
                <p-dropdown styleClass="w-full" (onChange)="initialize(true)"
                    [options]="articleOptions" formControlName="feiRules" [editable]="true" [filter]="false"
                    optionLabel="article" optionValue="article" appendTo="body"
                    placeholder="Wybierz z listy lub wpisz" i18n-placeholder>
                    <ng-template let-item pTemplate="item">
                        {{ item.descr }}
                    </ng-template>
                </p-dropdown>
            </div>
            <div class="field col-12 md:col-4">
                <div class="param-label" i18n>Typ:</div>
                <p-dropdown styleClass="w-full" (onChange)="initialize(true)"
                    [options]="compLocOptions" formControlName="compLoc"
                    optionLabel="name" optionValue="value" appendTo="body">
                </p-dropdown>
            </div>
            <div class="field col-12 md:col-4">
                <div class="param-label" i18n>Przepisy krajowe:</div>
                <input pInputText type="text" class="w-full" formControlName="nationalRules"/>
            </div>
            <div class="field col-12 md:col-4">
                <div class="param-label" i18n>Tabela:</div>
                <p-dropdown styleClass="w-full"
                    [options]="tableOptions" formControlName="table"
                    [filter]="false" optionLabel="name" optionValue="value" appendTo="body">
                </p-dropdown>
            </div>
            <div class="field col-12 md:col-4">
                <div class="param-label" i18n>Język tabelki:</div>
                <p-dropdown
                    styleClass="w-full"
                    [options]="tableLanguageOptions"
                    formControlName="tableLanguage"
                    optionLabel="name" optionValue="value"
                    appendTo="body"></p-dropdown>
            </div>
        </div>
        <!-- Dystans, tempo i czas -->
        <ng-container *ngIf="paramsActiveItem === paramsTabMenuItems[1]">
            <div id="params-distance" class="grid pt-4">

                <!-- row 1 -->

                <div class="col-3 p-3 flex align-items-center" [class.text-red-600]="view.cfg.form.controls.speed.invalid" i18n>Tempo [m/min]:</div>
                <div class="col-4 p-3 flex align-items-center">
                    <p-dropdown
                        class="w-full" styleClass="w-full" [options]="speedOptions" formControlName="speed"
                        optionLabel="name" optionValue="value" appendTo="body"
                        (onChange)="establishTimeLimitLimitsToDisplay()">
                    </p-dropdown>
                </div>
                <div class="col-5"></div>

                <!-- row 2 -->

                <div class="col-12"></div>

                <!-- row 3 -->
                 
                <div class="col-3 distance-grid" style="border-top: 0; border-left: 0;">
                </div>
                <div class="col-4 distance-grid">
                    <span *ngIf="view.canvas && view.canvas.obstaclePath.firstRound" i18n>Runda 1</span>
                </div>
                <div class="col-4 distance-grid">
                    <span *ngIf="view.canvas && view.canvas.obstaclePath.secondRound" i18n>Runda 2</span>
                </div>
                <div class="col-1"></div>

                <!-- row 4 -->

                <div class="col-3 distance-grid" i18n>Długość trasy wyliczona</div>
                <div class="col-4 distance-grid" [class.line-through]="view.cfg.form.controls.overrideDistance1.value">
                    {{ view.canvas && view.canvas.obstaclePath.firstRound ? (view.canvas.obstaclePath.firstRound.length | length: view.cfg.params.distanceUnit : true) : '' }}
                </div>
                <div class="col-4 distance-grid" [class.line-through]="view.cfg.form.controls.overrideDistance2.value">
                    {{ view.canvas && view.canvas.obstaclePath.secondRound ? (view.canvas.obstaclePath.secondRound.length | length: view.cfg.params.distanceUnit : true) : '' }}
                </div>
                <div class="col-1"></div>

                <!-- row 5 -->

                <div class="col-3 distance-grid" i18n>Długość trasy ustawiona ręcznie</div>
                <div class="col-4 distance-grid">
                    <div *ngIf="view.canvas && view.canvas.obstaclePath.firstRound" class="w-full flex flex-row">
                        <p-checkbox class="pr-2" formControlName="overrideDistance1" [binary]="true"
                                    inputId="overrideDistance1" (onChange)="initialize(false)"
                                    pTooltip="Zaznacz aby ręcznie podać dystans pierwszej rundy" i18n-pTooltip tooltipPosition="top">
                        </p-checkbox>
                        <p-inputNumber class="w-full" styleClass="w-full" inputStyleClass="w-full" formControlName="distance1M"
                                    [showButtons]="true" [useGrouping]="false"
                                    (onInput)="establishTimeLimitLimitsToDisplay()"
                                    incrementButtonClass="param-input-number-button" decrementButtonClass="param-input-number-button"
                                    [min]="0" [max]="5000" [step]="5">
                        </p-inputNumber>
                    </div>
                </div>
                <div class="col-4 distance-grid">
                    <div *ngIf="view.canvas && view.canvas.obstaclePath.secondRound" class="w-full flex flex-row">
                        <p-checkbox class="pr-2" formControlName="overrideDistance2" [binary]="true"
                                    inputId="overrideDistance2" (onChange)="initialize(false)"
                                    pTooltip="Zaznacz aby ręcznie podać dystans drugiej rundy" i18n-pTooltip tooltipPosition="top">
                        </p-checkbox>
                        <p-inputNumber class="w-full" styleClass="w-full" inputStyleClass="w-full" formControlName="distance2M"
                                    [showButtons]="true" [useGrouping]="false"
                                    (onInput)="establishTimeLimitLimitsToDisplay()"
                                    incrementButtonClass="param-input-number-button" decrementButtonClass="param-input-number-button"
                                    [min]="0" [max]="5000" [step]="5">
                        </p-inputNumber>
                    </div>
                </div>
                <div class="col-1"></div>

                <!-- row 6 -->

                <ng-container *ngIf="firstRoundTimeLimit || secondRoundTimeLimit || view.cfg.form.controls.timeLimit.enabled">
                    <div class="col-3 distance-grid" [class.text-red-600]="view.cfg.form.controls.timeLimit.invalid"
                        [pTooltip]="timeLimitTooltip">
                        <div class="flex flex-column">
                            <span i18n>Norma czasu</span>
                            <span *ngIf="timeLimitLimits">{{ timeLimitLimits }}</span>
                        </div>
                    </div>

                    <div class="col-4 distance-grid" [pTooltip]="timeLimitTooltip"
                        *ngIf="!view.cfg.form.controls.timeLimit.enabled">
                        {{ firstRoundTimeLimit }}
                    </div>
                    <div class="col-4 distance-grid"
                        *ngIf="view.cfg.form.controls.timeLimit.enabled">
                            <p-inputNumber class="w-full" styleClass="w-full" inputStyleClass="w-full" formControlName="timeLimit"
                                        [showButtons]="true" [useGrouping]="false"
                                        incrementButtonClass="param-input-number-button" decrementButtonClass="param-input-number-button"
                                        [min]="getTimeLimitLimitsRangeForm().min" [max]="getTimeLimitLimitsRangeForm().max" [step]="1"
                                        [class.ng-dirty]="view.cfg.form.controls.timeLimit.invalid"
                                        placeholder="Automatyczna" i18n-placeholder
                                        [pTooltip]="timeLimitTooltip" tooltipPosition="top">
                            </p-inputNumber>
                    </div>
                    <div class="col-4 distance-grid" [pTooltip]="timeLimitTooltip">
                        {{ secondRoundTimeLimit }}
                    </div>
                    <div class="col-1"></div>
                </ng-container>
            </div>
        </ng-container>

        <!-- Wysokości -->
        <ng-container *ngIf="paramsActiveItem === paramsTabMenuItems[2]">
            <div class="grid py-4">
                <div class="heights-grid heights-grid-top col-1 font-bold" i18n>Klasa</div>
                <div class="heights-grid heights-grid-top col-2 font-bold" i18n>Zakres</div>
                <div class="heights-grid heights-grid-top col-4 font-bold" i18n>Wysokość</div>
                <div class="col-5"></div>
                
                <ng-container *ngIf="view.cfg.form.controls.classes.value.length === 0">
                    <div class="heights-grid col-1" i18n>Bez klasy</div>
                    <div class="heights-grid col-2">{{ getHeightLimitsForm() }}</div>
                    <div class="heights-grid col-4">
                        <p-inputNumber class="w-full" styleClass="w-full" inputStyleClass="w-full"
                            formControlName="obstacleHeight"
                            [showButtons]="true" [useGrouping]="false"
                            incrementButtonClass="param-input-number-button" decrementButtonClass="param-input-number-button"
                            [min]="view.cfg.formLimits.height?.min" [max]="view.cfg.formLimits.height?.max" [step]="5"
                            [class.ng-dirty]="view.cfg.form.controls.obstacleHeight.invalid">
                        </p-inputNumber>
                    </div>
                    <div class="col-5"></div>
                </ng-container>

                <ng-container formGroupName="obstacleHeights" *ngFor="let c of getSortedClassesForm()">
                    <ng-container *ngIf="view.cfg.formLimits.heights && formValueToClassNo(c) as cn">
                        <div class="heights-grid col-1">
                            {{ limitsService.classNoNames[cn] }}
                        </div>
                        <div class="heights-grid col-2">
                            {{ getHeightLimitsForm(c) }}
                        </div>
                        <div class="heights-grid col-4">
                            <p-inputNumber class="w-full" styleClass="w-full" inputStyleClass="w-full"
                                [formControlName]="cn"
                                [showButtons]="true" [useGrouping]="false"
                                incrementButtonClass="param-input-number-button" decrementButtonClass="param-input-number-button"
                                [min]="view.cfg.formLimits.heights[cn]?.min" [max]="view.cfg.formLimits.heights[cn]?.max" [step]="5"
                                [class.ng-dirty]="isObstacleHeightDirty(cn)">
                            </p-inputNumber>
                        </div>
                        <div class="col-5"></div>
                    </ng-container>
                </ng-container>
            </div>
        </ng-container>

        <!-- Szerokości i inne -->
        <ng-container *ngIf="paramsActiveItem === paramsTabMenuItems[3]">
            <div id="params-dimensions">
                <p-panel styleClass="mt-2 shadow-none border-1 border-200">
                    <ng-template pTemplate="header">
                        <span class="text-base font-bold"
                            pTooltip="Rozmiary wszystkich przeszkód danego rodzaju, dla których nie ustawiono szerokości indywidualnie."
                            tooltipPosition="left" i18n-pTooltip i18n>Szerokość i długość przeszkód</span>
                    </ng-template>
                    <div class="formgrid grid align-items-end py-0 my-0">
                        <div class="field col-12 md:col-4 param-line">
                            <div class="param-label" [class.text-red-600]="view.cfg.form.controls.obstacleLength.invalid">
                                <span i18n>Długość przeszkód</span><span> {{ getLengthLimitsForm() }}:</span>
                            </div>
                            <p-inputNumber class="w-full" styleClass="w-full" inputStyleClass="w-full" formControlName="obstacleLength"
                                [showButtons]="true" [useGrouping]="false"
                                incrementButtonClass="param-input-number-button" decrementButtonClass="param-input-number-button"
                                [min]="view.cfg.formLimits.length!.min" [max]="view.cfg.formLimits.length!.max" [step]="50"
                                [class.ng-dirty]="view.cfg.form.controls.obstacleLength.invalid">
                            </p-inputNumber>
                        </div>
                        <div class="field col-12 md:col-4 param-line">
                            <div class="param-label" [class.text-red-600]="view.cfg.form.controls.oxerWidth.invalid">
                                <span i18n>Szerokość oksera</span><span> {{ getOxerWidthLimitsForm() }}:</span>
                            </div>
                            <p-inputNumber class="w-full" styleClass="w-full" inputStyleClass="w-full" formControlName="oxerWidth"
                                [showButtons]="true" [useGrouping]="false"
                                incrementButtonClass="param-input-number-button" decrementButtonClass="param-input-number-button"
                                [min]="view.cfg.formLimits.width!.oxer.min" [max]="view.cfg.formLimits.width!.oxer.max" [step]="10"
                                [class.ng-dirty]="view.cfg.form.controls.oxerWidth.invalid">
                            </p-inputNumber>
                        </div>
                        <div class="field col-12 md:col-4 param-line">
                            <div class="param-label" [class.text-red-600]="view.cfg.form.controls.tripleBarWidth.invalid">
                                <span i18n>Szerokość triple bar</span><span> {{ getTripleBarreWidthLimitsForm() }}:</span>
                            </div>
                            <p-inputNumber class="w-full" styleClass="w-full" inputStyleClass="w-full" formControlName="tripleBarWidth"
                                [showButtons]="true" [useGrouping]="false"
                                incrementButtonClass="param-input-number-button" decrementButtonClass="param-input-number-button"
                                [min]="view.cfg.formLimits.width!.tripleBarre.min" [max]="view.cfg.formLimits.width!.tripleBarre.max" [step]="10"
                                [class.ng-dirty]="view.cfg.form.controls.tripleBarWidth.invalid">
                            </p-inputNumber>
                        </div>
                        <div class="field col-12 md:col-4 param-line">
                            <div class="param-label" [class.text-red-600]="view.cfg.form.controls.ditchWidth.invalid">
                                <span i18n>Szerokość rowu</span><span> {{ getDitchWidthLimitsForm() }}:</span>
                            </div>
                            <p-inputNumber class="w-full" styleClass="w-full" inputStyleClass="w-full" formControlName="ditchWidth"
                                [showButtons]="true" [useGrouping]="false"
                                incrementButtonClass="param-input-number-button" decrementButtonClass="param-input-number-button"
                                [min]="view.cfg.formLimits.width!.ditch!.min" [max]="view.cfg.formLimits.width!.ditch!.max" [step]="10"
                                [class.ng-dirty]="view.cfg.form.controls.ditchWidth.invalid">
                            </p-inputNumber>
                        </div>
                        <div class="field col-12 md:col-4 param-line">
                            <div class="param-label" [class.text-red-600]="view.cfg.form.controls.liverPoolWidth.invalid">
                                <span i18n>Szerokość liverpool</span><span> {{ getLiverPoolWidthLimitsForm() }}:</span>
                            </div>
                            <p-inputNumber class="w-full" styleClass="w-full" inputStyleClass="w-full" formControlName="liverPoolWidth"
                                [showButtons]="true" [useGrouping]="false"
                                incrementButtonClass="param-input-number-button" decrementButtonClass="param-input-number-button"
                                [min]="view.cfg.formLimits.width!.liverpool!.min" [max]="view.cfg.formLimits.width!.liverpool!.max" [step]="10"
                                [class.ng-dirty]="view.cfg.form.controls.liverPoolWidth.invalid">
                            </p-inputNumber>
                        </div>
                        <div class="field col-12 md:col-4 param-line">
                            <div class="param-label" [class.text-red-600]="view.cfg.form.controls.wallWidth.invalid">
                                <span i18n>Szerokość muru</span><span> {{ getWallWidthLimitsForm() }}:</span>
                            </div>
                            <p-inputNumber class="w-full" styleClass="w-full" inputStyleClass="w-full" formControlName="wallWidth"
                                [showButtons]="true" [useGrouping]="false"
                                incrementButtonClass="param-input-number-button" decrementButtonClass="param-input-number-button"
                                [min]="view.cfg.formLimits.width!.wall!.min" [max]="view.cfg.formLimits.width!.wall!.max" [step]="10"
                                [class.ng-dirty]="view.cfg.form.controls.wallWidth.invalid">
                            </p-inputNumber>
                        </div>
                        <div class="field col-12 md:col-4 param-line">
                        </div>
                    </div>
                </p-panel>
                <p-panel styleClass="mt-2 shadow-none border-1 border-200">
                    <ng-template pTemplate="header">
                        <span class="text-base font-bold"
                            pTooltip="Odległości wpływające na obliczenia toru i przeszkód."
                            tooltipPosition="left" i18n-pTooltip i18n>Odległości</span>
                    </ng-template>
                    <div class="formgrid grid align-items-end py-0 my-0">
                        <div class="field col-12 md:col-4 param-line">
                            <div class="param-label" [class.text-red-600]="view.cfg.form.controls.compMinDist.invalid">
                                <span i18n>Minimalna odległość przeszkód w szeregu [m]</span>
                            </div>
                            <p-inputNumber class="w-full" styleClass="w-full" inputStyleClass="w-full" formControlName="compMinDist"
                                [showButtons]="true" [useGrouping]="false"
                                incrementButtonClass="param-input-number-button" decrementButtonClass="param-input-number-button"
                                [min]="1" [max]="view.cfg.form.controls.compMaxDist.value" [step]="1"
                                [class.ng-dirty]="view.cfg.form.controls.compMinDist.invalid">
                            </p-inputNumber>
                        </div>
                        <div class="field col-12 md:col-4 param-line">
                            <div class="param-label" [class.text-red-600]="view.cfg.form.controls.compMaxDist.invalid">
                                <span i18n>Maksymalna odległość przeszkód w szeregu [m]</span>
                            </div>
                            <p-inputNumber class="w-full" styleClass="w-full" inputStyleClass="w-full" formControlName="compMaxDist"
                                [showButtons]="true" [useGrouping]="false"
                                incrementButtonClass="param-input-number-button" decrementButtonClass="param-input-number-button"
                                [min]="view.cfg.form.controls.compMinDist.value" [max]="50" [step]="1"
                                [class.ng-dirty]="view.cfg.form.controls.compMaxDist.invalid">
                            </p-inputNumber>
                        </div>
                        <div class="field col-12 md:col-4 param-line">
                            <div class="param-label" [class.text-red-600]="view.cfg.form.controls.jumpBeforeLenM.invalid">
                                <span i18n>Długość wyskoku przed przeszkodą [m]</span>
                            </div>
                            <p-inputNumber class="w-full" styleClass="w-full" inputStyleClass="w-full" formControlName="jumpBeforeLenM"
                                [showButtons]="true" [useGrouping]="false"
                                incrementButtonClass="param-input-number-button" decrementButtonClass="param-input-number-button"
                                [min]="0" [max]="4" [step]="0.5"
                                [class.ng-dirty]="view.cfg.form.controls.jumpBeforeLenM.invalid">
                            </p-inputNumber>
                        </div>
                        <div class="field col-12 md:col-4 param-line">
                            <div class="param-label" [class.text-red-600]="view.cfg.form.controls.landAfterLenM.invalid">
                                <span i18n>Długość lądowania za przeszkodą [m]</span>
                            </div>
                            <p-inputNumber class="w-full" styleClass="w-full" inputStyleClass="w-full" formControlName="landAfterLenM"
                                [showButtons]="true" [useGrouping]="false"
                                incrementButtonClass="param-input-number-button" decrementButtonClass="param-input-number-button"
                                [min]="0" [max]="4" [step]="0.5"
                                [class.ng-dirty]="view.cfg.form.controls.landAfterLenM.invalid">
                            </p-inputNumber>
                        </div>
                        <div class="field col-12 md:col-4 param-line">
                            <div class="param-label" [class.text-red-600]="view.cfg.form.controls.strideMinLenM.invalid">
                                <span i18n>Minimalna długość fouli [m]</span>
                            </div>
                            <p-inputNumber class="w-full" styleClass="w-full" inputStyleClass="w-full" formControlName="strideMinLenM"
                                [showButtons]="true" [useGrouping]="false"
                                incrementButtonClass="param-input-number-button" decrementButtonClass="param-input-number-button"
                                [min]="2" [max]="view.cfg.form.controls.strideMaxLenM.value" [step]="0.5"
                                [class.ng-dirty]="view.cfg.form.controls.strideMinLenM.invalid">
                            </p-inputNumber>
                        </div>
                        <div class="field col-12 md:col-4 param-line">
                            <div class="param-label" [class.text-red-600]="view.cfg.form.controls.strideMaxLenM.invalid">
                                <span i18n>Maksymalna długość fouli [m]</span>
                            </div>
                            <p-inputNumber class="w-full" styleClass="w-full" inputStyleClass="w-full" formControlName="strideMaxLenM"
                                [showButtons]="true" [useGrouping]="false"
                                incrementButtonClass="param-input-number-button" decrementButtonClass="param-input-number-button"
                                [min]="view.cfg.form.controls.strideMinLenM.value" [max]="5" [step]="0.5"
                                [class.ng-dirty]="view.cfg.form.controls.strideMaxLenM.invalid">
                            </p-inputNumber>
                        </div>
                    </div>
                </p-panel>
            </div>
        </ng-container>
        <!-- Udostępnianie -->
        <div *ngIf="paramsActiveItem === paramsTabMenuItems[4]" class="pt-3">
            <div class="field">
                <div class="param-label" i18n>Widoczność:</div>
                <p-selectButton
                    styleClass="w-full" [unselectable]="true"
                    formControlName="shareMode"
                    [options]="shareModes"
                    optionLabel="label" optionValue="value"></p-selectButton>
            </div>
        </div>
    </form>
    <ng-template pTemplate="footer">
        <div class="flex justify-content-between align-items-center">
            <div *ngIf="paramsFormSizesInvalid()" class="text-red-500">
                <span i18n>Popraw paramtery na zakładkach</span>
                <span> "{{ paramsTabMenuItems[2].label }}", "{{ paramsTabMenuItems[3].label }}"</span>
            </div>
            <div class="text-red-500">
                {{ errorMsg }}
            </div>
            <div class="flex justify-content-end">
        <p-button icon="pi pi-times" (click)="onCancelDialog()" label="Anuluj" i18n-label severity="danger"></p-button>
        <p-button icon="pi pi-check" (click)="onOkDialog()" [disabled]="!view.cfg.form.valid" label="Zapisz dane" i18n-label></p-button>
    </div>
</div>
</ng-template>
</p-dialog>
