import { SafeHtml } from '@angular/platform-browser'

import paper from 'paper'
import shortUUID, { SUUID } from 'short-uuid'

import { DesignBaseObject } from '../../design.schema'

import { ReplaySubject, Subscription } from 'rxjs'
import { LayerId, ParkourCanvas } from '../../parkour-canvas/parkour-canvas'
import { ParkourConfig } from '../../parkour.config'
import { StorageService } from '../../services/storage.service'
import { DetailComponent } from '../detail.component'
import { Selectable } from '../detail.selection'
import { CircleWithHandle, Selector, SelectorKind } from '../detail.selectors'
import { UiCommandId, UiContextMenu } from '../detail.ui.commands.defs'
import { ObjectGenerator } from './generator'

export type ParkourObjectKind = {
    kind: string,
    icon?: string,
    svg?: SafeHtml,
    url?: string,
    bars?: number,
    stripes?: number,
    name: string,
    menuText?: string,
    doNotDropOnPath?: boolean,
    doNotShowInChangeList?: boolean,
    objectClass: new (config: ParkourObjectConfig) => ParkourObject | ObjectGenerator,
}

export enum ParkourObjectDecorations {
    grass = 'grass',
    water = 'water',
    singlePond = 'singlePond',
    pondLeft = 'pondLeft',
    pondRight = 'pondRight',
    doublePond = 'doublePond'
}

export type ParkourObjectConfig = {
    cfg: ParkourConfig,
    canvas: ParkourCanvas,
    store: StorageService,
    subs: Subscription,
    object: { [ID: string]: any },
    view?: DetailComponent,
}

/*

    Object hierarchy:
        - ParkourObject (abstract)
            - SvgImage (abstract) - content of the object can be downloaded from url that contains SVG file
                - Landscape
                - InOutMarker
                - PathObject (abstract) - path can be placed through these objects
                    - Terminal - beginning or end of the path
                    - FinishStart - an interphase trigger between first and second round
                    - Obstacle
                        - ObstacleWithBars
            - TextBox
            - UserImage
            - Drawing

    All objects that are not descendants of PathObject are considered to be regular objects and will appear on
    the drop down list for objects tab when editing object properties. They can also be converted between each other.

    Initiatization logic:
        - Object Constructor
            - Determine object size based on object kind and configuration and set in objectSize
            - Call objectReady() when visuals and objectSize are ready (can be asynchronous)
        ...
        - ParkourObject objectReady()
            - Create external frame
            - Create internal frame
            - Create selection group graphics
            - Call drawExtraElements to draw elements that depend on frames and objectSize
        - Object drawExtraElements

*/

export abstract class ParkourObject implements Selectable {
    // external is the boundary of all graphics that object displays
    // including decorations and symbols. it is centered in the object center
    // (rotation axis) which can be different to the graphics center
    externalFrame: paper.Path.Rectangle
    // object is the boundary of the physical object, it corresponds
    // to the object in reality
    readonly objectSize: paper.Size
    objectFrame?: paper.Path.Rectangle
    protected initialPos: paper.Point
    protected noPosition: boolean = false
    public parentSelector?: Selectable

    public readonly ready: ReplaySubject<void> = new ReplaySubject<void>()
    public selected: boolean = false
    changed: boolean = false
    selectedBeforeMouseDown: boolean = false
    angle: number
    uuid: SUUID
    kind: ParkourObjectKind
    public levelItem: paper.Item
    public selector: Selector
    public get focusLock(): boolean {
        return false
    }

    contextMenu?: UiContextMenu
    protected canvas: ParkourCanvas
    protected cfg: ParkourConfig
    protected store: StorageService
    protected subs: Subscription
    protected view?: DetailComponent
    protected isReady: boolean = false

    // Features
    protected readonly snapRotation: boolean = false   // when rotating snap to 0/90/180/270 angles
    protected readonly canRotate: boolean = true       // user can rotate object
    protected readonly canMove: boolean = true         // user move object
    protected readonly canResize: boolean = false      // user can resize object
    protected readonly preferredSelectorKind: SelectorKind = SelectorKind.ANY
    protected readonly preferredLayer: LayerId = LayerId.OBSTACLES
    protected readonly configurableLayer: boolean = false
    private _layer: LayerId = LayerId.NONE

    set layer(layer: LayerId) {
        if (this._layer !== layer) {
            this._layer = layer
            this.canvas.getLayer(layer).addChild(this.allGroup)
            this.allGroup.sendToBack()
        }
    }

    get layer(): LayerId {
        return this._layer
    }

    get index(): number {
        return this.allGroup.index
    }

    get bounds(): paper.Rectangle {
        return this.externalFrame.bounds
    }

    isSelected(): boolean {
        return this.selected
    }

    update() {
        this.createSelectionGfx()
        this.drawExtraElements()
    }

    //
    // Group hierarchy
    //
    // Per object:
    //
    //     - allGroup
    //         - selGroup
    //         - external & object frames
    //         - object contents
    //
    //     - linesOfSightGroup
    //         - lineOfSight1
    //         - lineOfSight2
    //
    // Per path node:
    //         - labelGroup
    //             - obstacle labels
    //
    // In view:
    //
    //     -pathGroup1(2)
    //         - midPoints1(2)
    //         - forwardPath
    //         - thickPath
    //         - distance
    //     - rulers
    //
    protected readonly allGroup = new paper.Group()   // all drawable elements of the object

    constructor(protected config: ParkourObjectConfig) {
        this.canvas = config.canvas
        this.cfg = config.cfg
        this.view = config.view
        this.store = config.store
        this.subs = config.subs
        const object = config.object
        this.uuid = object.uuid || shortUUID.generate()
        this.kind = object.kind || 'unknown'
        this.selector = new CircleWithHandle(this, config.cfg)
        if (!object.x || !object.y) {
            this.noPosition = true
        }
        this.initialPos = new paper.Point(object.x || 0, object.y || 0)
        this.angle = object.angle || 0
        this.layer = object.layer || this.preferredLayer
        this.levelItem = this.allGroup
        this.allGroup.visible = false
        this.objectSize = new paper.Size(350, 350)
        this.externalFrame = this._createFrame(this.initialPos, this.objectSize, false)
        this.allGroup.addChild(this.externalFrame)
        // when object is created and its graphics is not ready, the size is not known
        this.allGroup.sendToBack();

        if (config.view) {
            this.contextMenu = new UiContextMenu(config.view)
            const c = config.view.uiCommands
            this.contextMenu.add(
                c.getContextMenuItem(UiCommandId.DELETE_OBJECT),
                c.getContextMenuItem(UiCommandId.ROTATE_RIGHT),
                c.getContextMenuItem(UiCommandId.ROTATE_LEFT),
                c.getContextMenuItem(UiCommandId.REMOVE_FROM_GROUP),
                c.getContextMenuItem(UiCommandId.GROUP_OBJECTS),
                c.getContextMenuItem(UiCommandId.UNGROUP_OBJECTS)
            )
        }
    }

    setVisibility(visible: boolean) {
        this.allGroup.visible = visible
    }

    // should be overridden by child class to draw object parts after object is ready
    drawExtraElements() {
    }

    doubleClick(): boolean {
        return false
    }

    fade() {
        this.allGroup.opacity = 0.4
    }

    unfade() {
        this.allGroup.opacity = 1
    }

    getKindName(): string {
        return this.kind.name
    }

    getRotation(): number {
        return this.angle
    }

    // update scale so scaled size is not smaller that given minimum size, keeping the aspect ratio
    protected _trimScale(oldSize: paper.Size, scale: number, minSize: paper.Size): number {
        const newSize = oldSize.multiply(scale)
        const scaleToMin = newSize.divide(minSize)
        const s = 1 / Math.min(scaleToMin.width, scaleToMin.height)
        return s < 1 ? scale : 1
    }

    // Returns the rectangle size of all graphics that the object has, including not only
    // the physiscal object but all decorations, texts, labels.
    abstract getExternalSize(): paper.Size

    // returns the center position / rotation point of the object
    // don't override this method in child classes, the center is managed in the base class
    getPosition(): paper.Point {
        return this.externalFrame.position
    }

    getPositionInField(): paper.Point {
        return this.getPosition().subtract(this.canvas.field.topLeft)
    }

    // reset object and update dynamically drawn elements
    reset() {
    }

    edit(point?: paper.Point): void {
    }

    sendToBack() {
        this.allGroup.sendToBack()
    }

    bringToFront() {
        this.allGroup.bringToFront()
    }

    moveUpInLayer() {
        const s = this.allGroup.nextSibling
        if (s) {
            this.allGroup.insertAbove(s)
        }
    }

    moveDownInLayer() {
        const s = this.allGroup.previousSibling
        if (s) {
            this.allGroup.insertBelow(s)
        }
    }

    private _createFrame(pos: paper.Point, size: paper.Size, visible: boolean): paper.Path.Rectangle {
        let frame = new paper.Path.Rectangle(pos.subtract(size.divide(2)), size)
        frame.strokeColor = new paper.Color('#888')
        frame.strokeWidth = this.cfg.params.lineWidth
        frame.strokeScaling = false
        frame.visible = visible
        return frame
    }

    // should be called by object renderer when object is ready to display
    protected objectReady(success: boolean) {
        if (this.isReady) {
            return
        }
        if (this.canvas.paper) {
            this.rotateGroups(this.angle, this.getPosition())
            this.createSelectionGfx()
            this.drawExtraElements()
            if (!success) {
                this.configExternalFrame(true)
            }
        }
        this.isReady = true
        if (success) {
            this.ready.next()
        } else {
            this.ready.error(new Error('Object '+ this.constructor.name + ' creation error'))
        }
    }

    protected configExternalFrame(border: boolean, fillEnable?: boolean, fillColor?: string, strokeColor?: string) {
        this.externalFrame.visible = border || fillEnable || false
        this.externalFrame.strokeColor = new paper.Color(border ? (strokeColor ? strokeColor : '#000') : fillColor || '#000')
        if (fillEnable && fillColor) {
            this.externalFrame.fillColor = new paper.Color(fillColor)
        } else {
            this.externalFrame.fillColor = null
            this.externalFrame.visible = border
        }
    }

    public createSelectionGfx() {
        const pos = this.getPosition()
        let size = this.getExternalSize()
        if (size) {
            const oldframe = this.externalFrame
            const visible = oldframe.visible || this.cfg.debugParams.imgBoundingBox
            this.externalFrame = this._createFrame(pos, size, visible)
            this.externalFrame.rotate(this.angle, pos)
            oldframe.replaceWith(this.externalFrame)
            this.externalFrame.sendToBack()
        }
        size = this.objectSize
        if (size) {
            const oldframe = this.objectFrame
            this.objectFrame = this._createFrame(pos, size, this.cfg.debugParams.imgBoundingBox)
            this.objectFrame.rotate(this.angle, pos)
            oldframe ? oldframe.replaceWith(this.objectFrame) : this.allGroup.addChild(this.objectFrame)
        }
        this.allGroup.visible = true

        const item = this.selector.create(this.getPosition(), this.getExternalSize(), this.angle, this.canRotate, this.preferredSelectorKind)
        if (item) {
            this.allGroup.addChild(item)
        }
    }

    setPosition(newPos: paper.Point) {
        this._move(newPos.subtract(this.getPosition()))
    }

    isObstacle() {
        return false
    }

    isObstacleWithBars() {
        return false
    }

    isTerminal() {
        return false
    }

    isStart() {
        return false
    }

    isFinish() {
        return false
    }

    isFinishStart() {
        return false
    }

    destroy() {
        if (this.allGroup.isInserted()) {
            this.allGroup.remove()
        }
        this.selector.destroy()
    }

    contains(point: paper.Point): boolean {
        if (this.levelItem?.contains(point)) {
            return true
        }
        return false
    }

    isInside(rect: paper.Rectangle): boolean {
        return this.externalFrame.isInside(rect)
    }

    select(point?: paper.Point) {
        // process select before bringing object to the front because
        // bringToFront seems to break tweening
        this.selector.select(point)
        // show selected object on top of all other objects
        if (this.layer === LayerId.OBSTACLES) {
            this.allGroup.bringToFront()
        }

        this.selected = true
        this.changed = false
    }

    drag(delta: paper.Point, point: paper.Point) {
        this.selector.drag(delta, point)
    }

    move(delta: paper.Point) {
        if (this.canMove) {
            this._move(delta)
        }
    }

    protected _move(delta: paper.Point) {
        this.changed = true
        this.allGroup.translate(delta)
    }

    rotate(angleDelta: number) {
        let angle = (this.angle + angleDelta + 360) % 360
        if (!this.canRotate) {
            return
        }
        if (this.snapRotation) {
            angle = this.selector.snap(angle)
        }
        this.changed = true
        this.rotateGroups(angle - this.angle, this.getPosition())
        this.angle = angle
        this.rotationDone()
    }

    protected rotateGroups(angle: number, position: paper.Point) {
        if (angle !== 0) {
            this.allGroup.rotate(angle, position)
        }
    }

    rotationDone() {
    }

    // to allow resizing the object, return true in scaleObject in child class
    scaleObject(scale: number): boolean {
        return false
    }

    deselect() {
        if (!this.selected) {
            return
        }
        if (this.canvas?.paper) {
            this.selector.deselect()
        }
        this.selected = false
        this.changed = false
    }

    toJson(): DesignBaseObject {
        return {
            uuid: this.uuid as string,
            kind: this.kind.kind,
            x: this.getPosition().x,
            y: this.getPosition().y,
            angle: this.angle,
            layer: this.layer != this.preferredLayer ? this.layer : null
        } as DesignBaseObject
    }
}
